import React from 'react'
import DialerKey from './DialerKey.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

export default class Dialer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dialed: '',
      readyToDial: true
    }

    this.auth = props.auth

    this.clearDialed = this.clearDialed.bind(this)
    this.backspaceDialed = this.backspaceDialed.bind(this)
    this.appendToDialed = this.appendToDialed.bind(this)
    this.makeCall = this.makeCall.bind(this)
    this.handleNumberChange = this.handleNumberChange.bind(this)
  }

  backspaceDialed() {
    const current = this.state.dialed
    const newDialed = current.slice(0,-1)
    this.setState({dialed: newDialed})
  }

  clearDialed() { this.setState({dialed: ''}) }

  appendToDialed(v) {
    const current = this.state.dialed
    const newDialed = current + v
    this.setState({dialed: newDialed})
  }

  handleButtonPush(event, value, special) {
    event.preventDefault()
    if(special === true) {
      console.log("Ignoring Special for now")
    } else {
      this.appendToDialed(value)
    }
  }

  handleNumberChange(event) {
    console.log("NUMBER CHANGE")
    const val = event.target.value
    this.setState({dialed: val})
  }

  isValidPhoneNumber(number) {
    // TODO: Add Phone Number Validation here
    return true
  }

  makeCall() {
    const dialedNumber = '+' + this.state.dialed
    this.props.makeCall(dialedNumber)
  }

  render () {
    let alert

    if(this.props.errorMessages.length > 0) {
      alert = <div className="text-red-700 bg-red-100 border-1 rounded border-red-200 p-2 mb-2">{this.props.errorMessages}</div>
    }

    return(
        <div className='my-4'>
          <div>
            <div>
              {alert}
            </div>
          </div>
          <div>
            <div>
              <div id="dialed" className="text-center">
                <form>
                  <div>
                    <input
                      className="w-full text-lg p-2 border border-gray-400 bg-gray-200 rounded text-center"
                      type="number"
                      placeholder="Enter Number"
                      defaultValue={this.state.dialed}
                      onChange={this.handleNumberChange}
                    />
                  </div>
                </form>
              </div>
              <div id="wrapper">
                <DialerKey value="1" clickFn={this.appendToDialed} />
                <DialerKey value="2" clickFn={this.appendToDialed} subtext="abc" />
                <DialerKey value="3" clickFn={this.appendToDialed} subtext="def" />
                <div className="clear"></div>
                <DialerKey value="4" clickFn={this.appendToDialed} subtext="ghi" />
                <DialerKey value="5" clickFn={this.appendToDialed} subtext="jkl" />
                <DialerKey value="6" clickFn={this.appendToDialed} subtext="mno" />
                <div className="clear"></div>
                <DialerKey value="7" clickFn={this.appendToDialed} subtext="pqrs" />
                <DialerKey value="8" clickFn={this.appendToDialed} subtext="tuv" />
                <DialerKey value="9" clickFn={this.appendToDialed} subtext="wxyz" />
                <div className="clear"></div>
                <DialerKey value="*" clickFn={this.appendToDialed} special={true} />
                <DialerKey value="0" clickFn={this.appendToDialed} subtext="+" />
                <DialerKey value="#" clickFn={this.appendToDialed} special={true} />
                <div className="clear"></div>
                <button className="key" onClick={this.clearDialed} >
                  <FontAwesomeIcon icon="times" size="sm" />
                </button>
                <button className="key phone" rel="Make Call" disabled={!this.state.readyToDial} onClick={this.makeCall}>
                  <FontAwesomeIcon icon="phone" size="sm" />
                </button>
                <button className="key" onClick={this.backspaceDialed}>
                  <FontAwesomeIcon icon="backspace" size="sm" />
                </button>
                <div className="clear"></div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

Dialer.propTypes = {
  auth: PropTypes.object.isRequired,
  errorMessages: PropTypes.array.isRequired,
  makeCall: PropTypes.func.isRequired,
}
