import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Heading = ({ text, icon }) => {
  var iconTag = null

  if (icon) {
    iconTag = (
      <FontAwesomeIcon icon={icon} />
    )
  }

  return (
    <header className='border-b border-gray-500 my-4'>
      <h6 className='text-gray-500 px-2'>
        {iconTag} {text}
      </h6>
    </header>
  )
}

Heading.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string
}

export default Heading
