import actionCable from 'actioncable';
import { API_WS_ROOT } from '../../constants';

export default class WebSocketServer {
  constructor(jwt, conversationId, onReceive) {

    // let endpoint = 'ws://localhost:3030/cable?jwt='
    // if (process.env.NODE_ENV === 'production') {
    //   endpoint = API_WS_ROOT + '?jwt=' + this.jwt
    // }
    let endpoint = API_WS_ROOT + '?jwt=' + jwt

    this.cable = actionCable.createConsumer(endpoint)
    this.room = this.cable.subscriptions.create({
      channel: "ConversationChannel",
      from: "user",
      conversation_id: conversationId,
      session_id: this.getRndInteger(1,9999999999999)
    }, {
      connected: () => {
        this.room.join();
      },
      received: (data) => {
        if (data) {
          if (data.type) {
            onReceive(data.type, data);
          } else {
            console.warn("Missing data.type");
            console.warn(data);
          }
        }
      },
      join: () => {
        if (this.room.joined) { return; };
        this.room.joined = true;
        return this.room.perform("join", {});
      },
      typing: () => {
        this.room.perform("typing", {});
      }
    });
  }

  //Improve This
  getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min) ) + min;
  }

  displayText(message) {
    this.room.perform('display_text', { message: message })
  }

  close() {
    this.leave()
    this.room.consumer.disconnect()
    this.cable.disconnect()
  }

  action(data) { this.room.perform('action', data) }
  agent() { this.room.perform('agent', {}) }
  leave() { this.room.perform('leave', {}) }
  typing() { this.room.perform('typing', {}) }
  call() { this.room.perform('call', {}) }
  dtmf(digit) { this.room.perform('dtmf', {digit: digit}) }
}
