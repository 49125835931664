export default class ActionManager {
  constructor() {
    this.registeredActions = []
  }

  registerAction (name, action) {
    // warn that the action is already registered
    if (name) {
      this.registeredActions.push({name: name, action: action})
    } else {
      console.warn('Name param is required to register an Action')
    }
  }

  invokeAction (name, data) {
    // warn that action was not found (or throw?)
    const actions = this.registeredActions.filter((action) => {
      return action.name === name
    })

    if (actions.length > 0) {
      actions.forEach((action) => {
        action.action(data);
      })
    } else {
      console.warn(`NO ACTION FOUND: ${name}`)
      console.warn(data)
    }
  }

  listActions() {
    this.registeredActions.forEach((action) => {
      console.log(action.name)
    })
  }
}
