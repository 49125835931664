import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router"
import Dialer from './dialer/Dialer.js'
import Styles from './dialer/Styles.js'

class DialerContainer extends React.Component {
  constructor(props) {
    super(props)

    this.auth = props.auth
    this.state = {
      activeConversation: (this.getConversationFromParams() || null),
      errorMessages: []
    }
  }

  makeCall(dialedNumber) {
    // const dialedNumber = '+' + this.state.dialed
    const route = "/api/conversations"
    const body = JSON.stringify({conversation: {phone_number: dialedNumber }})
    const options = { method: "POST", body: body }

    this.auth.fetch(route, options).then(
      response => {
        if (response.errors) {
          console.log(`Phone number error: ${response.errors}`)
          this.setState({
            errorMessages: ["Phone number not yet supported."]
          })
          console.log(this.state)
        } else {
          this.props.selectConversationHandler(response)
        }
      }
    )
  }

  getConversationFromParams() {
    // let number
    //
    // if(this.props.computedMatch && this.props.computedMatch.params) {
    //   number = this.props.computedMatch.params.number
    // }
    //
    // if(!number) { return null }

    // const route = "/api/conversations"
    // const body = JSON.stringify({conversation: {phone_number: number}})
    // const options = { method: "POST", body: body }
    //
    // this.auth.fetch(route, options).then(
    //   response => {
    //     if(response.errors) {
    //       console.log("Phone number not found, rendering the Dialer.")
    //       this.setState({
    //         errorMessages: [...this.state.errorMessages, response.errors]
    //       })
    //     } else {
    //       this.setState({ activeConversation: response })
    //     }
    //   }
    // )
  }

  isAlreadyActive(conversation) {
    return (this.state.activeConversation
      && (this.state.activeConversation.id === conversation.id))
  }

  selectConversation(conversation) {
    if(!this.isAlreadyActive(conversation)) {
      this.setState({activeConversation: conversation})
      this.props.history.push(`/conversations/${conversation.phone_number}`)
    }
  }

  render() {
    return(
      <div>
        <Styles>
          <Dialer
            auth={this.auth}
            selectConversationHandler={this.selectConversation.bind(this)}
            makeCall={this.makeCall.bind(this)}
            errorMessages={this.state.errorMessages}
            />
        </Styles>
      </div>
    )
  }
}

export default withRouter(DialerContainer)

DialerContainer.propTypes = {
  computedMatch: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
}
