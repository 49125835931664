import React from 'react';
import Authentication from "../../Authentication";


// We want to render the component no matter if it's authed or not
// we'll handle the redirecting in the private / route
export default function withAuth(AuthComponent) {
  const Auth = new Authentication();

  return class AuthWrapped extends React.Component {
    state = { payload: null,  loaded: false };

    componentDidMount() {
      if (Auth.isAuthenticated()) {
        try {
          const payload = Auth.getTokenPayload();
          this.setState({
            payload: payload,
            loaded: true
          });
        } catch(error) {
          Auth.logout();
        };
      };
    };

    render() {
      return (
        <AuthComponent
          auth={Auth}
          history={this.props.history}
          payload={this.state.payload}
        />
      );
    }
  };
}
