import React from 'react';
import PropTypes from 'prop-types'

const messages = {
  'typing': 'Agent Typing ...',
  'connected': 'Agent connected.',
  'disconnected': 'Agent disconnected'
}

const PresenceMessage = function ({ message, bottom }) {
  const body = messages[message.body.type] || JSON.stringify(message.body.type, null, 2)

  return (
    <div ref={bottom} className={`presence ${message.from} mb-2 text-center text-gray-600`}>
      {body}
    </div>
  )
}

PresenceMessage.propTypes = {
  message: PropTypes.object.isRequired,
  bottom: PropTypes.object.isRequired
}

export default PresenceMessage
