import React from 'react'
import PropTypes from 'prop-types'
import ListItem from './ListItem'
import Heading from '../ui/Heading.js'

const List = function({listName, numbers, listIcon}) {
  const items = numbers.map((number) => {
    return (
      <ListItem key={number.number} number={number}/>
    )
  })
  return (
    <div className='mb-4'>
      <Heading text={listName} icon={listIcon}/>
      <div className="px-2">
        { items }
      </div>
    </div>
  )
}

List.propTypes = {
  listName: PropTypes.string.isRequired,
  listIcon: PropTypes.string,
  numbers: PropTypes.array.isRequired
}

export default List
