import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled'


const StyledIvrInput = styled('div')`
  .header-container {
    background-color: ${props => props.primaryColor}
    border-color: ${props => props.primaryColor}
  }
`

const DigitButton = function({digit, onClick}) {
  return (
    <button className="mr-1 px-3 py-2 rounded shadow bg-gray-300 font-bold" onClick={() => onClick(digit)}>
      {digit}
    </button>
  )
}

const IvrInput = function({ primaryColor, sendDTMF, isIvrDev, hasDtmf, voiceConnected }) {
  if (!isIvrDev || !hasDtmf || !voiceConnected) { return null }

  return(
    <StyledIvrInput primaryColor={primaryColor}>
      <div className="flex flex-row justify-start pt-3">
        <DigitButton digit='*' onClick={sendDTMF}/>
        <DigitButton digit={0} onClick={sendDTMF}/>
        <DigitButton digit={1} onClick={sendDTMF}/>
        <DigitButton digit={2} onClick={sendDTMF}/>
        <DigitButton digit={3} onClick={sendDTMF}/>
        <DigitButton digit={4} onClick={sendDTMF}/>
        <DigitButton digit={5} onClick={sendDTMF}/>
        <DigitButton digit={6} onClick={sendDTMF}/>
        <DigitButton digit={7} onClick={sendDTMF}/>
        <DigitButton digit={8} onClick={sendDTMF}/>
        <DigitButton digit={9} onClick={sendDTMF}/>
        <DigitButton digit='#' onClick={sendDTMF}/>

      </div>
    </StyledIvrInput>
  )
}

IvrInput.propTypes = {
  primaryColor: PropTypes.string,
  sendDTMF: PropTypes.func.isRequired,
  isIvrDev: PropTypes.bool.isRequired,
  hasDtmf: PropTypes.bool.isRequired,
  voiceConnected: PropTypes.bool.isRequired,
}

export default IvrInput
