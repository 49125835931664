import decode from 'jwt-decode'
import { API_ROOT } from './constants';

export default class Authentication {
  constructor(domain, cookieName) {
    this.domain = domain || API_ROOT;
    this.cookieName = cookieName || "_smart_prompt_token";
  }

  signUp = (email, password, passwordConfirmation) => {
    console.log("Posting /api/user (signup) with " + email + " & " + password + " & " + passwordConfirmation);
    const route = '/api/user';
    const payload = {
      user: {
        email: email,
        password: password,
        password_confirmation: passwordConfirmation
      },
      device: { identifier: "browser" }
    };

    return this.fetch(route, {
      method: "POST",
      body: JSON.stringify(payload)
    }).then(response => {
      this.setToken(response.jwt);
      return Promise.resolve(response);
    });
  };

  login = (email, password) => {
    console.log("Calling /api/authentication with " + email + " & " + password);

    const route = '/api/authentication';

    const payload = {
      user: { email: email, password: password },
      device: { identifier: "browser" }
    }

    return this.fetch(route, {
      method: "POST",
      body: JSON.stringify(payload)
    }).then(response => {
      this.setToken(response.jwt);
      return Promise.resolve(response);
    });
  };

  fetch = (route, options) => {
    const url = this.domain + route;
    const headers = {
      "Content-Type": "application/json",
      "Accept": "application/api.smartprompt.com; version=1",
      "Access-Control-Allow-Headers": "*"
    };

    if (this.isAuthenticated()) {
      headers["Authorization"] = this.getHeader();
    }

    return fetch(url, { headers, ...options })
    //      .then(this._checkStatus)
      .then(response => response.json());
  }

  _checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }

  getEmail = () => {
    let payload = this.getTokenPayload();
    if (payload) {
      return payload.email;
    } else {
      return null;
    };
  };

  hasRole = (role) => {
    let payload = this.getTokenPayload();
    if (payload) {
      return payload.roles.indexOf(role) > -1;
    } else {
      return null;
    };
  };

  getHeader = () => {
    return "Bearer " + this.getToken();
  }

  getTokenPayload = () => {
    let token = this.getToken();
    if (token) {
      return decode(token);
    } else {
      return null
    };
  }

  getToken = () => {
    return localStorage.getItem(this.cookieName);
  };

  setToken = (jwt) => {
    localStorage.setItem(this.cookieName, jwt);
  };

  logout = () => {
    localStorage.removeItem(this.cookieName);
  };

  isAuthenticated = () => {
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  };

  isTokenExpired = (jwt) => {
    try {
      const decoded = decode(jwt)
      if (decoded.exp < Date.now() / 1000) {
        return true;
      } else return false;
    } catch(error) {
      return false;
    }
  };
}
