import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import placeholder_png from '../../images/placeholder.png'

const ListItem = function({number}) {
  return (
    <div className="flex my-2 border rounded-lg items-center bg-gray-200">
      <div className='p-2'>
        <img className='rounded-full border w-20 h-20' src={number.logo_url || placeholder_png} alt={ number.name } />
      </div>
      <div className='flex-grow'>
        <h5 className='font-bold text-lg'>{ number.name }</h5>
        <h6 className='text-gray-600'>{ number.organization_name } {number.number}</h6>
      </div>
      <div className='p-2'>
        <a className="rounded-full text-white bg-gc-yellow text-xl p-2" href={ `/conversations/${number.number}` }>
          <FontAwesomeIcon icon='phone' />
        </a>
      </div>
    </div>
  )
}

ListItem.propTypes = {
  number: PropTypes.object.isRequired
}

export default ListItem
