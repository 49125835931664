import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CallControl = function({ voiceReady, voiceConnected, voiceRinging, onAnswerClick, onHangupClick, onToggleMute, muted}) {
  let buttons = []

  if (voiceConnected || voiceRinging) {
    if (muted) {
      buttons.push(
        <button className="rounded py-2 px-3 bg-red-500" key="voice-muted" onClick={onToggleMute}>
          <FontAwesomeIcon icon='microphone-slash' fixedWidth={true} />
        </button>
      )
    } else {
      buttons.push(
        <button className="rounded py-2 px-3 bg-green-500" key="voice-muted" onClick={onToggleMute}>
          <FontAwesomeIcon icon='microphone' fixedWidth={true} />
        </button>
      )
    }
    buttons.push(
      <button className="rounded py-2 px-3 bg-red-500" key="voice-hangup" onClick={onHangupClick}>
        <FontAwesomeIcon icon='phone-slash' fixedWidth={true} />
      </button>
    )
  }

  if (voiceRinging) {
    buttons.push(
      <button className="rounded py-2 px-3 bg-green-500" key="voice-answer" onClick={onAnswerClick}>
        <FontAwesomeIcon icon='phone' fixedWidth={true} />
      </button>
    )
  }

  return(
    <div className="pt-3 justify-end flex-grow">
      <div className="flex flex-row">
        { buttons }
      </div>
    </div>
  )
}

CallControl.propTypes = {
  voiceConnected: PropTypes.bool.isRequired,
  voiceReady: PropTypes.bool.isRequired,
  voiceRinging: PropTypes.bool.isRequired,
  onAnswerClick: PropTypes.func.isRequired,
  onHangupClick: PropTypes.func.isRequired,
  onToggleMute: PropTypes.func.isRequired,
  muted: PropTypes.bool.isRequired
}

export default CallControl
