import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router";
import Profile from './settings/Profile.js';

class SettingsContainer extends React.Component {
  constructor(props) {
    super(props)
    console.log("Settings")

    // TODO pass handler to profile
              // <Nav.Link onClick={() => { this.auth.logout(); this.history.push('/');}}>
              //   <FontAwesomeIcon icon="sign-out" />
              //   <span>&nbsp;Log Out</span>
              // </Nav.Link>
  }

  render() {
    return (
      <Profile auth={this.props.auth} />
    )
  }
}

SettingsContainer.propTypes = {
  computedMatch: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
}

export default withRouter(SettingsContainer)
