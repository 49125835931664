import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Shortcut = function({ iconUrl, url, label, onClick }) {
  let icon

  if (iconUrl.slice(0, 3) === 'fas') {
    icon = (<FontAwesomeIcon icon={iconUrl.slice(4)} fixedWidth />)
  } else {
    icon = (<img src={iconUrl} width='16' height='16' alt='icon' />)
  }

  return(
    <span>
      <button
        className="text-sm px-3 py-1 rounded-lg border border-gc-yellow mr-2 last:mr-0"
        key={url}
        onClick={onClick}
        data-url={url}>
      {icon}&nbsp;{label}&nbsp;
      </button>
      &nbsp;
    </span>
  )
}

Shortcut.propTypes = {
  iconUrl: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

const ConversationMenu = function({menu, menuClick}) {
  let shortcuts
  let Menu = null

  if (menu && menu.body && menu.body.shortcuts) {
    shortcuts = menu.body.shortcuts.map(shortcut => (
      <Shortcut
        key={shortcut.url}
        iconUrl={shortcut.icon_url}
        url={shortcut.url}
        label={shortcut.label}
        onClick={menuClick} />
    ))
  }

  if (shortcuts && shortcuts.length > 0) {
    Menu = <div as="h6">
      <div className="d-flex justify-content-center border-l border-r border-b border-gc-yellow p-2">
        <div>
          {shortcuts}
        </div>
      </div>
    </div>
  }

  return(Menu)
}

ConversationMenu.propTypes = {
  menu: PropTypes.object,
  menuClick: PropTypes.func.isRequired
}

export default ConversationMenu
