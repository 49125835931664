import React from 'react'
import { withRouter } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AuthNav = ({ auth, location }) => {
  const activeKey = location.pathname

  const tabClasses = 'w-1/3 py-4 px-1 text-center border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300'

  const tabActive = 'w-1/3 py-4 px-1 text-center border-b-2 border-indigo-500 font-medium text-sm leading-5 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700'
  // " aria-current="page"
  const tabStyle = (path) => {
    if (path === activeKey) {
      return tabActive
    }
    return tabClasses
  }

  return (
    auth.isAuthenticated() === true ? (
      <div className='block w-full'>
        <div className='border-b border-gray-200'>
          <nav className='-mb-px flex'>
            <a href='/directory' className={tabStyle('/directory')}>
              <FontAwesomeIcon icon='list' />
              <span>&nbsp;Directory</span>
            </a>
            <a href='/dialer' className={tabStyle('/dialer')}>
              <FontAwesomeIcon icon='phone-rotary' />
              <span>&nbsp;Dialer</span>
            </a>
            <a href='/settings' className={tabStyle('/settings')}>
              <FontAwesomeIcon icon='user-cog' />
              <span>&nbsp;Settings</span>
            </a>
          </nav>
        </div>
      </div>
    ) : null
  )
}

export default withRouter(AuthNav)
