import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import CapBottom from '../ui/CapBottom.js'

const StyledConversationFooter = styled('div')`
  .header-container {
    background-color: ${props => props.primaryColor}
    border-color: ${props => props.primaryColor}
  }
`

const MessageInput = function ({ children, primaryColor }) {
  return(
    <StyledConversationFooter primaryColor={primaryColor}>
      <CapBottom>
        { children }
      </CapBottom>
    </StyledConversationFooter>
  )
}

MessageInput.propTypes = {
  children: PropTypes.array.isRequired,
  primaryColor: PropTypes.string
}

export default MessageInput
