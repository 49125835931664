import React from 'react'
import LogoSVG from '../../images/goodcall-logo.svg'

class Header extends React.Component {
  render () {
    return (
      <div className='text-center py-4'>
        <a href='/'>
          <img
            className='mx-auto'
            src={LogoSVG}
            alt='Goodcall'
            width='150'
          />
        </a>
      </div>
    )
  }
}

export default Header
