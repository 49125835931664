import Twilio from 'twilio-client'

export default class ConversationVoiceServer {
  constructor(actionManager) {
    this.actionManager = actionManager
    this.identity = null
    this.outgoing = null
  }

  onVoiceToken (msg) {
    console.log("Voice Token")
    console.log(msg)

    this.actionManager.registerAction('VoiceAnswer', this.onVoiceAnswer.bind(this))
    this.actionManager.registerAction('VoiceHangup', this.onVoiceHangup.bind(this))

    this.device = new Twilio.Device()
    this.connection = this.device.setup(msg.body.token, {
      codecPreferences: ['opus','pcmu'], fakeLocalDTMF: true
    })

    this.device.on('ready', (device) => {
      console.log("VOICEREADY")
      this.actionManager.invokeAction('VoiceReady', device)
    })
    this.device.on('error', (error) => {
      this.actionManager.invokeAction('VoiceError', error)
    })
    this.device.on('connect', (conn) => {
      this.actionManager.invokeAction('VoiceConnected', conn)
    })
    this.device.on('disconnect', (conn) => {
      this.actionManager.invokeAction('VoiceDisconnected', conn)
    })
    this.device.on('incoming', (conn) => {
      this.actionManager.invokeAction('VoiceIncoming', conn)
    })

    this.device.on('cancel', (conn) => {
      this.actionManager.invokeAction('VoiceCancelled', conn)
    })
    // Drops connection or token is invalid / expired
    this.device.on('offline', (conn) => {
      this.actionManager.invokeAction('VoiceOffline', conn)
    })
  }

  makeCall() {
    if(this.outgoing === null) { return }

    if(this.device.status() !== 'ready') {
      console.log("Wait for device to be ready!")
      return window.setTimeout(this.makeCall.bind(this), 500)
    }

    this.actionManager.invokeAction('VoiceCalling', { outgoing: this.outgoing })

    const params = { To: this.outgoing }

    if(this.device) {
      console.log('Connecting to ' + params.To + '...')
      this.device.connect(params)
    }
  }

  onVoiceAnswer(conn) {
    console.log("voice answer")
    conn.accept()
  }

  onVoiceHangup (conn) {
    console.log("hangup conversation voice server")
    if (conn.status() === "open") {
      conn.disconnect()
    } else {
      conn.reject()
    }
    this.device.disconnectAll()
    this.actionManager.invokeAction("VoiceDisconnected", conn)
  }

  speakerDevices () {
    this.device.audio.speakerDevices.get()
  }

  ringtoneDevices () {
    this.device.audio.ringtoneDevices.get()
  }

  audioDevices() {
    this.device.audio.availableOutputDevices.get()
  }

  close () {
    if (this.device) {
      this.device.disconnectAll()
      this.device.destroy()
    }
  }
}
