import React from 'react';

export default class DialerKey extends React.Component {
  constructor(props) {
    super(props)

    this.value = props.value || ''
    this.subtext = props.subtext || ''
    this.special = props.special || false
    this.clickFn = props.clickFn
  }

  render() {
    return(
      <button className="key" onClick={() => this.clickFn(this.value)} >
        {this.value}
        <span>
          {this.subtext}
        </span>
      </button>
    )
  }
}
