import styled from '@emotion/styled'

const Styles = styled('div')`
  #phone {
    background-size: contain;
    height:655px;
  }
  #wrapper {
    width:252px;
    height:410px;
    margin:0 auto 0 auto;
    position:relative;
    padding:24px 0 0 0;
    background:#fff;
  }
  #dialed{
    height: 50px;
    background:#f8f9fa;
  }
  .key {
    border-radius: 50px 50px 50px 50px;
    color:#444;
    width:70px;
    height:70px;
    text-align:center;
    font-size:30px;
    float:left;
    box-sizing:border-box;
    padding:5px 0 0 0;
    margin:0 7px 10px 7px;
    border:1px solid #444;
    cursor:pointer;
  }
  .key span {
    display:block;
    color:#444;
    text-align:center;
    font-size:12px;
    text-transform:uppercase;
  }
  .key.special {
    line-height:60px;
  }
  .key.nb {
    border:none;
  }
  .key.phone {
    background:#5CDB74;
    border:none;
    color:#FFF;
    line-height:60px;
    font-size:40px;
  }
  .clear {
    clear:both;
  }
`

export default Styles
