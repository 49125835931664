import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AgentCallButton = function({ voiceReady, voiceRinging, voiceConnected, agentConnected, requestAgent, onCallClick, hasAgentVoice }) {
  if (!hasAgentVoice) { return null }

  const buttons = []
  buttons.push(
    <button className={`rounded py-2 px-3 bg-${agentConnected ? 'blue' : 'green'}-500`} key="chat" disabled={agentConnected} onClick={requestAgent}>
      <FontAwesomeIcon icon='user-friends' fixedWidth={true}/>
    </button>
  )

  if (agentConnected && voiceReady && !(voiceRinging || voiceConnected)) {
    buttons.push(
      <button className="rounded py-2 px-3 bg-green-500" key="voice-call" onClick={onCallClick}>
        <FontAwesomeIcon icon='phone' fixedWidth={true}/>
      </button>
    )
  }
  return(
    <div class="flex flex-row">
      { buttons }
    </div>
  )
}

AgentCallButton.propTypes = {
  voiceReady: PropTypes.bool.isRequired,
  voiceRinging: PropTypes.bool.isRequired,
  voiceConnected: PropTypes.bool.isRequired,
  requestAgent: PropTypes.func.isRequired,
  onCallClick: PropTypes.func.isRequired,
  agentConnected: PropTypes.bool.isRequired,
  hasAgentVoice: PropTypes.bool.isRequired,
}

export default AgentCallButton
