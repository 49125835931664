import React from 'react';

export default class NoMatch extends React.Component {
  render() {
    return(
      <div className="loginForm text-center">
        <div className="form-login">
          <h1 className="h1 mb-3">404</h1>
        </div>
      </div>
    );
  }
}
