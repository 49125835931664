import React from 'react';
import PropTypes from 'prop-types';

const InfoMessage = function ({ message, bottom }) {
  return (
    <div ref={bottom} className={`info ${message.from} mb-2 text-center text-gray-600`}>
      {message.from} - {message.body} - {message.updated_at}
    </div>
  )
}

InfoMessage.propTypes = {
  message: PropTypes.object.isRequired,
  bottom: PropTypes.object.isRequired
}

export default InfoMessage
