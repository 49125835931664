import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import AuthWebSocketServer from './AuthWebSocketServer'
import MessageList from '../conversation/MessageList'
import CapTop from '../ui/CapTop.js'
import CapBottom from '../ui/CapBottom.js'

class AuthenticationContainer extends React.Component {
  constructor (props) {
    super(props)

    this.bottom = React.createRef()
    this.auth = props.auth

    this.onReceive = this.onReceive.bind(this)
    this.widgetAction = this.widgetAction.bind(this)

    this.wss = new AuthWebSocketServer(this.onReceive)

    this.state = {
      messages: [],
      redirectToHome: this.auth.isAuthenticated()
    }
  }

  onReceive (type, data) {
    switch (type) {
      case 'PublicTokenMessage':
        this.setToken(data)
        break
      case 'PublicWidgetMessage':
        this.addMessageToView(data)
        break
      default:
        console.log("Don't know how to handle that :/.")
    }
  }

  widgetAction (data) {
    this.wss.action(data)
  }

  setToken (data) {
    this.auth.setToken(data.body)
    this.setState({
      redirectToHome: true
    })
  }

  addMessageToView (data) {
    this.setState({
      messages: [...this.state.messages, data]
    })
  }

  componentWillUnmount () { this.wss.close() }

  componentDidUpdate () {
    if (this.bottom.current) {
      this.bottom.current.scrollIntoView()
    }
  }

  render () {
    if (this.state.redirectToHome === true) { return <Redirect to='/directory' /> }

    return (
      <div className='flex flex-col flex-grow self-stretch w-full h-0 max-h-full'>
        <CapTop />
        <MessageList
          messages={this.state.messages}
          bottomRef={this.bottom}
          jwt=''
          widgetAction={this.widgetAction}
        />
        <CapBottom />
      </div>
    )
  }
}

AuthenticationContainer.propTypes = {
  auth: PropTypes.object.isRequired
}

export default AuthenticationContainer
