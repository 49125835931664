import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Search = function({query, queryRef, handleSearch}) {
  return (
    <div className="p-2 mt-2">
      <form className="" id="profile-form" onSubmit={handleSearch}>
        <div className='flex'>
          <div className='flex-grow' controlid="formSearch">
            <label htmlFor="query" className="sr-only">Search</label>
            <input
              className="p-2 bg-gray-100 border border-r-0 border-gray-500 rounded-l-md block w-full sm:text-sm sm:leading-5"
              ref={queryRef}
              defaultValue={query}
              placeholder='Search'
              name="query"
              type="text"
            />
          </div>
          <div className="">
            <button className="rounded-r-md bg-gray-400 border border-l-0 border-gray-500 py-2 px-4 sm:text-sm sm:leading-5" type="submit" variant="primary" >
              <FontAwesomeIcon icon='search' />
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

Search.propTypes = {
  queryRef: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
  query: PropTypes.string
}

export default Search
