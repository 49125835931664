import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AgentTextForm = function({ message, onChange, onSubmit, hasAgentText }) {
  if (!hasAgentText) { return null }

  return(
    <form onSubmit={onSubmit} className="message-input-form flex-grow">
      <div className="flex flex-row">
        <input
          className="w-full block p-2 rounded border mr-2"
          onChange={onChange}
          value={message}
          placeholder="Enter your message here..."
          type="text" />

        <button className="rounded py-2 px-3 bg-blue-500" key="send" type='submit'>
          <FontAwesomeIcon icon='paper-plane' fixedWidth={true}/>
        </button>
      </div>
    </form>
  )
}

AgentTextForm.propTypes = {
  message: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  hasAgentText: PropTypes.bool.isRequired,
}

export default AgentTextForm
