import React from 'react'
import PropTypes from 'prop-types'

export default class ConversationVoiceUI extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      device: null,
      logs: [],
      outputDevices: [],
      inputDevices: [],
      showAudioControls: false,
      isVolumeSupported: false,
      inputVolume: 0,
      outputVolume: 0
    }
  }
  render() {
    return(null)
  }
}

ConversationVoiceUI.propTypes = {
  conversation: PropTypes.object.isRequired
}
