import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import * as consts from '../../constants'

const StyledConversationStatus = styled('div')`
  .status-container {
    margin-bottom: 20px;
  }
`

const ConversationStatus = ({status}) => {
  switch (status) {
    case consts.CONV_INVALID_NUMBER:
      // todo -> Dialer
      return (
        <StyledConversationStatus>
          <div border="error" className="status-container">
            <div>
              <p>Number not found</p>
              <a href="/directory">
                Directory
              </a>
              <a href="/dialer">
                Dialer
              </a>
            </div>
          </div>
        </StyledConversationStatus>
      )
    case consts.CONV_CONNECTING:
      return (
        <StyledConversationStatus>
          <div border="success" className="status-container">
            <div>
              Connecting...
            </div>
          </div>
        </StyledConversationStatus>
      )
    default: {
      return null
    }
  }
}

ConversationStatus.propTypes = {
  status: PropTypes.number.isRequired
}

export default ConversationStatus
