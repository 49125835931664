let apiRoot = 'http://smartprompt.test';
let apiWsRoot = 'wss://smartprompt.test/cable';

if (process.env.NODE_ENV === 'production') {
  apiRoot = 'https://app.smartprompt.com';
  apiWsRoot = 'wss://app.smartprompt.com/cable';
}

export const API_ROOT = apiRoot;
export const API_WS_ROOT = apiWsRoot;

export const CONV_CONNECTING = 1
export const CONV_CONNECTED = 2
export const CONV_INVALID_NUMBER = 3

export const ROLE_IVR_DEV = "ivr_dev"
export const ROLE_BETA = "beta"
