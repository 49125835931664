import React from 'react';
import PropTypes from 'prop-types'
import Heading from '../ui/Heading.js'
import { withRouter } from "react-router"
import * as consts from '../../constants'

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      passwordConfirmation: '',
      formValid: true
    };
    this.auth = props.auth;

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout(e) {
    e.preventDefault()
    this.auth.logout()
    this.props.history.push(`/`)
  }

  handleSubmit(e) {
    e.preventDefault()
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  componentDidMount() {
    const route = "/api/user";
    this.auth.fetch(route).then(
      result => this.setState(
        { email: result.email }
      )
    );
  }

  render() {
    const beta = this.auth.hasRole(consts.ROLE_BETA) ? (
      <span className="mr-2 mb-2 px-2 py-1 text-sm rounded bg-green-500">Beta</span>
    ) : null

    const ivrDev = this.auth.hasRole(consts.ROLE_IVR_DEV) ? (
      <span className="mr-2 mb-2 px-2 py-1 text-sm rounded bg-green-500">IVR Dev</span>
    ) : null

    return(
      <div>
        <Heading text='Profile' icon='user'/>

        {
          beta || ivrDev ? (
            <div className="px-2 pb-4">
              { beta }
              { ivrDev }
            </div>
          ) : null
        }

        <form className="px-2 mb-8" id="profile-form" onSubmit={this.handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">Email</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="email"
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                type="email"
                className="form-input block w-full sm:text-sm sm:leading-5"
                placeholder="you@example.com"
              />
            </div>
          </div>

          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">Password</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="password"
                name="password"
                value={this.state.password}
                onChange={this.handleInputChange}
                type="password"
                className="form-input block w-full sm:text-sm sm:leading-5"
              />
            </div>
            <span className="text-gray-600">
              Leave blank if you don't want to change your password.
            </span>
          </div>

          <div className="mb-4">
            <label htmlFor="passwordConfirmation" className="block text-sm font-medium leading-5 text-gray-700">Password Confirmation</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="passwordConfirmation"
                name="passwordConfirmation"
                value={this.state.passwordConfirmation}
                onChange={this.handleInputChange}
                type="password"
                className="form-input block w-full sm:text-sm sm:leading-5"
              />
            </div>
          </div>

          <div className="mb-4">
            <button className="rounded px-4 py-2 mr-2 border border-green-600 bg-green-400" type="submit" variant="primary" disabled={!this.state.formValid} >
              Update
            </button>

            <button className="rounded px-4 py-2 border border-gray-600 bg-gray-400" variant='default' onClick={this.handleLogout}>Log out</button>
          </div>
        </form>

        <Heading text='Legal' icon='balance-scale'/>

        <div className="px-2 mb-8">
          <p>
            <a className='text-gray-600' href='https://makeagoodcall.com/legal'>Terms & Conditions</a>
          </p>
          <p>
            <a className='text-gray-600' href='https://makeagoodcall.com/primary'>Privacy Policy</a>
          </p>
        </div>
      </div>
    )
  }
}

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(Profile)
