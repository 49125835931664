import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router"
import ConversationHeader from './conversation/Header.js'
import ConversationMenu from './conversation/Menu.js'
import WebSocketServer from './conversation/WebSocketServer.js'
import ConversationVoiceServer from './conversation/VoiceServer.js'
import ConversationVoiceUI from './conversation/VoiceUI.js'
import ActionManager from './conversation/ActionManager.js'
import MessageList from './conversation/MessageList.js'
import MessageInput from './conversation/MessageInput.js'
import ConversationStatus from './conversation/Status.js'
import IvrInput from './conversation/IvrInput.js'
import AgentTextForm from './conversation/AgentTextForm.js'
import AgentCallButton from './conversation/AgentCallButton.js'
import CallControl from './conversation/CallControl.js'
import * as consts from '../constants'

class ConversationContainer extends React.Component {
  constructor(props) {
    super(props)

    this.auth = props.auth

    this.state = {
      status: consts.CONV_CONNECTING,
      conversation: null,
      menu: null,
      message: '',
      messages: [],
      agentConnected: false,
      voiceConnected: false,
      voiceReady: false,
      voiceMuted: false,
      voiceRinging: false,
      muted: false,
      hasSmartprompt: false,
      hasDtmf: false,
      hasTextToSpeech: false,
      hasAgentText: false,
      hasAgentVoice: false,
      isIvrDev: this.auth.hasRole(consts.ROLE_IVR_DEV),
    }

    this.jwt = this.auth.getToken()
    this.bottom = React.createRef()

    this.menuClick = this.menuClick.bind(this)
    this.chatMessage = this.chatMessage.bind(this)
    this.chatChange = this.chatChange.bind(this)
    this.onReceive = this.onReceive.bind(this)
    this.widgetAction = this.widgetAction.bind(this)
    this.requestAgent = this.requestAgent.bind(this)
    this.onCallClick = this.onCallClick.bind(this)
    this.onAnswerClick = this.onAnswerClick.bind(this)
    this.onHangupClick = this.onHangupClick.bind(this)
    this.onToggleMute = this.onToggleMute.bind(this)
    this.sendDTMF = this.sendDTMF.bind(this)

    // Action Manager //
    this.actionManager = new ActionManager()

    this.actionManager.registerAction('ActionMessage', this.onActionMessage.bind(this))
    this.actionManager.registerAction('CallMessage', this.onCallMessage.bind(this))
    this.actionManager.registerAction('DebugMessage', this.onDebugMessage.bind(this))
    this.actionManager.registerAction('InfoMessage', this.onInfoMessage.bind(this))
    this.actionManager.registerAction('PresenceMessage', this.onPresenceMessage.bind(this))
    this.actionManager.registerAction('MenuMessage', this.onMenuMessage.bind(this))
    this.actionManager.registerAction('HistoryMessage', this.onHistoryMessage.bind(this))
    this.actionManager.registerAction('VoiceTokenMessage', this.onVoiceTokenMessage.bind(this))
    this.actionManager.registerAction('WidgetMessage', this.onWidgetMessage.bind(this))

    this.actionManager.registerAction('agentdisconnected', this.onAgentDisconnected.bind(this))
    this.actionManager.registerAction('agentconnected', this.onAgentConnected.bind(this))
    this.actionManager.registerAction('agenttyping', this.onAgentTyping.bind(this))

    this.actionManager.registerAction('VoiceConnected', this.onVoiceConnected.bind(this))
    this.actionManager.registerAction('VoiceDisconnected', this.onVoiceDisconnected.bind(this))
    this.actionManager.registerAction('VoiceIncoming', this.onVoiceIncoming.bind(this))
    this.actionManager.registerAction('VoiceReady', this.onVoiceReady.bind(this))
    this.actionManager.registerAction('VoiceError', this.onVoiceError.bind(this))
    this.actionManager.registerAction('VoiceCancelled', this.onVoiceCancelled.bind(this))
    this.actionManager.registerAction('VoiceOffline', this.onVoiceOffline.bind(this))
    this.actionManager.registerAction('VoiceHangup', this.onVoiceHangup.bind(this))

    //This has to get the actionManager after the actions are registered.
    this.voiceServer = new ConversationVoiceServer(this.actionManager)
  }


  onReceive(type, data) {
    this.actionManager.invokeAction(type, data)
  }

  onActionMessage(data) {
    console.log(data)
    console.log("ACTION MESSAGE?")
  }
  onCallMessage(data) {
    console.log("Making call")
    this.voiceServer.makeCall()
    return data
  }

  onVoiceTokenMessage (data) {
    this.voiceServer.onVoiceToken(data)
    return data
  }
  onVoiceReady(device) {
    console.log("ONVOICEREADY")
    console.log(device)
    this.setState(Object.assign({}, this.state, { voiceReady: true}))
  }
  onVoiceError(error) {
    console.log("Voice Error")
    console.log(error)
    this.setState(Object.assign({}, this.state, { voiceConnected: false, voiceReady: false }))
    return error
  }
  onVoiceIncoming(conn) {
    console.log('Incoming connection from ' + conn.parameters.from)
    this.setState(Object.assign({}, this.state, { voiceRinging: true, conn: conn}))
    return conn
  }
  onVoiceConnected(conn) {
    console.log("Successfully connected!")
    this.setState(Object.assign({}, this.state, { voiceConnected: true, voiceRinging: false, conn: conn, muted: conn.isMuted() }))
    return conn
  }
  onVoiceDisconnected(conn) {
    console.log("Call Ended.")
    this.setState(Object.assign({}, this.state, { voiceConnected: false, voiceRinging: false, conn: conn }))
    return conn
  }
  onVoiceCancelled(conn) {
    console.log("Call cancelled.")
    this.setState(Object.assign({}, this.state, { voiceRingin: false, conn: conn }))
    return conn
  }
  onVoiceOffline(conn) {
    console.log("Voice offline.")
    this.setState(Object.assign({}, this.state, { voiceConnected: false, voiceReady: false }))
    return conn
  }
  onVoiceHangup(conn) {
    console.log("Voice hangup conversation container.")
    this.setState(Object.assign({}, this.state, { voiceRinging: false, conn: conn }))
    return conn
  }
  onInfoMessage(data) { this.addMessageToView(data) }
  onDebugMessage(data) { this.addMessageToView(data) }
  onWidgetMessage(data) { this.addMessageToView(data) }
  onMenuMessage(data) { this.setState({menu: data}) }
  onPresenceMessage(data) {
    if (data.from === "user" || data.body.actor === "user") {
      //Turn this on if you want to see connection & typing events for the User.
      //this.addMessageToView(data)
    } else {
      const actionType = data.body.actor + data.body.type
      this.actionManager.invokeAction(actionType, data)
    }
  }
  onHistoryMessage(data) {
    data.body.forEach((msg) => {
      this.actionManager.invokeAction(msg.type, Object.assign(msg, {history: true}))
    })
  }

  onAgentConnected(data) {
    this.setState(Object.assign({}, this.state, {agentConnected: true}))
    this.addMessageToView(data)
  }
  onAgentDisconnected(data) {
    this.setState(Object.assign({}, this.state, {agentConnected: false}))
    this.addMessageToView(data)
  }
  onAgentTyping(data) {
    if(!this.debounceAgentTyping) {
      this.addMessageToView(data)
      this.debounceAgentTyping = true
    }
    setTimeout(() => { this.debounceAgentTyping = false}, 5000)
  }

  requestAgent() {
    console.log("Requesting Agent")
    this.wSS.agent()
  }

  onCallClick() {
    console.log("Initiating call")
    // TODO call agent
  }

  onAnswerClick() {
    console.log("Answer call")
    if (this.state.conn) {
      console.log("Invoke answer")
      this.actionManager.invokeAction("VoiceAnswer", this.state.conn)
    }
  }
  onHangupClick() {
    console.log("Hangup call")
    console.log(this.state.conn)
    if (this.state.conn) {
      console.log("Invoke hangup")
      this.actionManager.invokeAction("VoiceHangup", this.state.conn)
    }
  }
  onToggleMute() {
    if (this.state.conn) {
      console.log(`Set mute ${!this.state.muted}`)
      this.state.conn.mute(!this.state.muted)
      this.setState(Object.assign({}, this.state, { muted: !this.state.muted }))
    }
  }

  chatChange (e) {
    this.setState(Object.assign({}, this.state, { message: e.target.value }))
    this.typingHandler(e)
  }

  typingHandler (e) {
    if (!this.debounceTyping) {
      this.wSS.typing()
      this.debounceTyping = true
    }
    setTimeout(() => { this.debounceTyping = false}, 5000)
  }

  chatMessage (e) {
    e.preventDefault()

    const message = this.state.message

    if (message === '') { return }

    this.setState(Object.assign({}, this.state, {message: ''}), () => {
      this.wSS.displayText(message)
    })
  }

  componentDidMount() {
    let number

    if (this.props.computedMatch && this.props.computedMatch.params) {
      number = this.props.computedMatch.params.number
    }

    if(!number) {
      this.setState({status: consts.CONV_INVALID_NUMBER})
      return
    }

    const route = "/api/conversations"
    const body = JSON.stringify({conversation: {phone_number: number}})
    const options = { method: "POST", body: body }

    this.auth.fetch(route, options).then(
      response => {
        if(response.errors) {
          console.log("Phone number not supported, rendering the Dialer.")
          this.setState({
            status: consts.CONV_INVALID_NUMBER
          })
        } else {
          console.debug("Setting up websocket")
          this.wSS = new WebSocketServer(this.jwt, response.id, this.onReceive)

          const caps = response.phone_number_capabilities

          const smartprompt = caps.includes("smartprompt")
          const dtmf = caps.includes("dtmf")
          const textToSpeech = caps.includes("text_to_speech")
          const agentText = caps.includes("agent_text")
          const agentVoice = caps.includes("agent_voice")

          this.setState(Object.assign({}, this.state, {
            status: consts.CONV_CONNECTED,
            conversation: response,
            hasSmartprompt: smartprompt,
            hasDtmf: dtmf,
            hasTextToSpeech: textToSpeech,
            hasAgentText: agentText,
            hasAgentVoice: agentVoice
          }))
        }
      }
    )
  }

  componentWillUnmount() {
    this.wSS && this.wSS.close()
    this.voiceServer.close()
  }

  componentDidUpdate() {
    if (this.bottom.current) {
      this.bottom.current.scrollIntoView()
    }

  }

  addMessageToView (data) {
    if (this.state.messages.some((msg) => msg.id === data.id)) { return }

    this.setState({
      messages: [...this.state.messages, data]
    })
  }

  menuClick (e) {
    e.preventDefault(e)
    const url = e.currentTarget.getAttribute('data-url')

    const actionData = { id: url, url: url, data: [] }
    this.wSS.action(actionData)
  }

  sendDTMF (digit) {
    console.log(digit)
    this.wSS.dtmf(digit)
  }

  widgetAction (data) {
    this.wSS.action(data)
  }

  render() {
    const conversationStatus = <ConversationStatus
      status = {this.state.status}
      conversation={this.state.conversation}
    />

    switch (this.state.status) {
      case consts.CONV_CONNECTED: {
        return(
          <div className="flex flex-col flex-grow self-stretch w-full h-0 max-h-full">
            {conversationStatus}
            <ConversationVoiceUI
              conversation={this.state.conversation}
              voiceReady={this.state.voiceReady}
              voiceConnected={this.state.voiceConnected}
              voiceMuted={this.state.voiceMuted}
            />
            <ConversationHeader
              conversation={this.state.conversation}
              agentConnected={this.state.agentConnected}
              voiceConnected={this.state.voiceConnected}
              voiceMuted={this.state.voiceMuted}
              voiceReady={this.state.voiceReady}
              hasSmartprompt={this.state.hasSmartprompt}
            />
            <ConversationMenu
              menu={this.state.menu}
              menuClick={this.menuClick}
            />
            <MessageList
              messages={this.state.messages}
              bottomRef={this.bottom}
              jwt={this.jwt}
              widgetAction={this.widgetAction}
              primaryColor={this.state.conversation.primary_color}
            />
            <MessageInput
              primaryColor={this.state.conversation.primary_color}
            >
              <div className="flex flex-row">
                <AgentTextForm
                  message={this.state.message}
                  onChange={this.chatChange}
                  onSubmit={this.chatMessage}
                  hasAgentText={this.state.hasAgentText}
                />
                <AgentCallButton
                  agentConnected={this.state.agentConnected}
                  voiceConnected={this.state.voiceConnected}
                  voiceReady={this.state.voiceReady}
                  requestAgent={this.requestAgent}
                  voiceRinging={this.state.voiceRinging}
                  onCallClick={this.onCallClick}
                  hasAgentVoice={this.state.hasAgentVoice}
                />
              </div>
              <div className="flex flex-row">
                <IvrInput
                  primaryColor={this.state.conversation.primary_color}
                  sendDTMF={this.sendDTMF}
                  isIvrDev={this.state.isIvrDev}
                  hasDtmf={this.state.hasDtmf}
                  voiceConnected={this.state.voiceConnected}
                />
                <CallControl
                  voiceConnected={this.state.voiceConnected}
                  voiceReady={this.state.voiceReady}
                  voiceRinging={this.state.voiceRinging}
                  onAnswerClick={this.onAnswerClick}
                  onHangupClick={this.onHangupClick}
                  onToggleMute={this.onToggleMute}
                  muted={this.state.muted}
                />
              </div>
            </MessageInput>
          </div>
        )
      }
      default:
        return (
          <div className='my-4'>{ conversationStatus }</div>
        )
    }
  }
}

ConversationContainer.propTypes = {
  auth: PropTypes.object.isRequired,
}

export default withRouter(ConversationContainer)
