import React from 'react';
import PropTypes from 'prop-types';
import PresenceMessage from './messages/PresenceMessage.js';
import InfoMessage from './messages/InfoMessage.js';
import DebugMessage from './messages/DebugMessage.js';
import WidgetMessage from './messages/WidgetMessage.js';

const MessageComponents = {
  'PresenceMessage': PresenceMessage,
  'InfoMessage': InfoMessage,
  'DebugMessage': DebugMessage,
  'WidgetMessage': WidgetMessage,
  'PublicWidgetMessage': WidgetMessage
}

const MessageList = function({messages, bottomRef, jwt, widgetAction, primaryColor}) {
  const messageList = messages.map(message => {
    const component = MessageComponents[message.type]
    return React.createElement(component, {
      key: message.id,
      message: message,
      bottom: bottomRef,
      widgetAction: widgetAction,
      jwt: jwt
    })
  })
  const borderStyle = primaryColor ? {} : {borderColor: primaryColor}
  return(
    <div
      className="MessagesContainer px-2 pt-4 flex-grow overflow-y-auto border-l border-r border-gc-yellow"
      style={borderStyle}
    >
      {messageList}
    </div>
  )
}

MessageList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  bottomRef: PropTypes.object.isRequired,
  jwt: PropTypes.string.isRequired,
  widgetAction: PropTypes.func.isRequired,
  primaryColor: PropTypes.string
}

export default MessageList
