import actionCable from 'actioncable'
import { API_WS_ROOT } from '../../constants'

export default class AuthWebSocketServer {
  constructor (onReceive) {
    const endpoint = API_WS_ROOT

    this.sessionId = this.uuidv4()

    this.cable = actionCable.createConsumer(endpoint)
    this.room = this.cable.subscriptions.create({
      channel: 'UserChannel',
      from: 'user',
      session_id: this.sessionId
    }, {
      connected: () => {
        this.room.join()
      },
      received: (data) => {
        if (data) {
          if (data.type) {
            onReceive(data.type, data)
          } else {
            console.warn('Missing data.type')
            console.warn(data)
          }
        }
      },
      join: () => {
        if (this.room.joined) { return };
        this.room.joined = true
        return this.room.perform('join', {})
      },

      disconnected: () => {
        console.log('Disconnected')
        console.log(this.args)
      },

      rejected: () => {
        console.log('Rejected')
        console.log(this.args)
      }
    })
  }

  uuidv4 () {
    var d = new Date().getTime()// Timestamp
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0// Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16// random number between 0 and 16
      if (d > 0) { // Use timestamp until depleted
        r = (d + r) % 16 | 0
        d = Math.floor(d / 16)
      } else { // Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0
        d2 = Math.floor(d2 / 16)
      }
      return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16)
    })
  }

  close () {
    this.leave()
    this.room.consumer.disconnect()
    this.cable.disconnect()
  }

  action (data) { this.room.perform('action', data) }
  leave () { this.room.perform('leave', {}) }
}
