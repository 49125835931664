import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import List from './directory/List.js'
// import Styles from './directory/Styles.js'
import Search from './directory/Search.js'

class DirectoryContainer extends React.Component {
  constructor(props) {
    super(props)

    this.auth = this.props.auth
    this.queryRef = React.createRef()

    this.state = {
      results: null,
      recent: []
    }

    this.handleSearch = this.handleSearch.bind(this)

    if (this.props.location.search && this.props.location.search !== "") {
      let params = this.props.location.search.substring(1)
        .split('&')
        .map((p) => {
          return p.split('=')
        })
        .reduce((m, p) => {
          m[p[0]] = p[1]
          return m
        }, {})

      if (params.q) {
        this.search(params.q)
      }
    }
  }

  componentDidMount() {
    const conversationRoute = "/api/conversations"

    this.auth.fetch(conversationRoute).then(
      result => this.setState({
        recent: result.sort(function(a,b) {
          return b.updated_at - a.updated_at
        }).map((i) => {
          return {
            organization_name: i.organization_name,
            name: i.phone_number_name,
            number: i.phone_number,
            prompt_url: null,
            logo_url: i.logo_url
          }
        })
      })
    )
  }

  search(query) {
    const directoryRoute = `/api/phone_numbers?q=${encodeURIComponent(query)}`

    this.auth.fetch(directoryRoute).then((result) => {
      this.setState(
        Object.assign({}, this.state, { query: query, results: result.data.sort() })
      )
    })
  }

  handleSearch(e) {
    const query = this.queryRef.current.value
    this.props.history.push(`?q=${encodeURIComponent(query)}`)
    this.search(query)
    e.preventDefault()
    return false
  }

  render() {
    const resultList = (this.state.results) ?
      <List listName="Results" listIcon="list" numbers={this.state.results} /> :
      null

    return (
      <div className="flex flex-col">
        <Search query={this.state.query} queryRef={this.queryRef} handleSearch={this.handleSearch}/>
        <div className="flex-grow overflow-y-auto">
          <List listName="Recent" listIcon="history" numbers={this.state.recent} />
          {resultList}
        </div>
      </div>
    )
  }
}

DirectoryContainer.propTypes = {
  computedMatch: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default withRouter(DirectoryContainer)
