import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from '@emotion/styled'

import CapTop from '../ui/CapTop.js'

const StyledConversationHeader = styled('div')`
  .header-container {
    background-color: ${props => props.conversation.primary_color}
    border-color: ${props => props.conversation.primary_color}
  }
`

const ConversationHeader = function({conversation, agentConnected, voiceConnected, voiceMuted, voiceReady, hasSmartprompt}) {
  let agentIcon
  let voiceIcon = (<FontAwesomeIcon className="text-gray-500" icon="phone" fixedWidth />)

  if (hasSmartprompt) {
    if (agentConnected) {
      agentIcon = (<FontAwesomeIcon className="text-green-500" icon="user" fixedWidth />)
    } else {
      agentIcon = (<FontAwesomeIcon className="text-gray-500" icon="user" fixedWidth />)
    }
  }

  if (voiceConnected) {
    voiceIcon = (
      <FontAwesomeIcon className="text-green-500" icon="phone" fixedWidth />
    )
  }

  return(
    <StyledConversationHeader conversation={conversation}>
      <CapTop>
        <div className="">
          <h2 className="text-lg text-medium inline pr-2 border-r border-black mr-2">{conversation.phone_number_name}</h2>
          <h3 className="text-gray-900 inline">{conversation.phone_number}</h3>
        </div>
        <div className="flex flex-row flex-grow justify-end">
          <span>
            {voiceReady}
          </span>
          <span>
            {agentIcon}
          </span>
          <span>
            {voiceIcon}
          </span>
          <a href="/directory">
            <FontAwesomeIcon className="text-red-500" icon="times" fixedWidth/>
          </a>
        </div>
      </CapTop>
    </StyledConversationHeader>
  )
}

ConversationHeader.propTypes = {
  conversation: PropTypes.object.isRequired,
  agentConnected: PropTypes.bool.isRequired,
  voiceConnected: PropTypes.bool.isRequired,
  voiceMuted: PropTypes.bool.isRequired,
  voiceReady: PropTypes.bool.isRequired,
  hasSmartprompt: PropTypes.bool.isRequired
}

export default ConversationHeader
