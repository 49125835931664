import React from 'react';
import PropTypes from 'prop-types'
import AuthenticationContainer from './authentication/AuthenticationContainer.js'

class HomeContainer extends React.Component {
  render () {
    return(
      <AuthenticationContainer auth={this.props.auth}/>
    );
  }
}

HomeContainer.propTypes = {
  auth: PropTypes.object.isRequired,
}

export default HomeContainer
