import React from 'react'
import './tailwind.generated.css'
import withAuth from './components/authentication/withAuth'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import ConversationContainer from './components/ConversationContainer.js'
import Nav from './components/site/Nav.js'
import Header from './components/site/Header.js'
import DirectoryContainer from './components/DirectoryContainer.js'
import SettingsContainer from './components/SettingsContainer.js'
import DialerContainer from './components/DialerContainer.js'
import HomeContainer from './components/HomeContainer.js'
import NoMatch from './components/site/NoMatch.js'
import { library } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'

library.add(
  fas
)

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest} render={(props) => (
      rest.auth.isAuthenticated() === true
        ? <Component {...rest} />
        : <Redirect to='/' />
    )}
  />
)

const PublicOnlyRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest} render={(props) => (
      rest.auth.isAuthenticated() === false
        ? <Component {...rest} />
        : <Redirect to='/directory' />
    )}
  />
)

class App extends React.Component {
  render () {
    return (
      <div className='container flex flex-col max-w-screen-sm mx-auto px-4 h-screen max-h-screen pb-4'>
        <Router>
          <Header />
          <Nav auth={this.props.auth} />
          <div className='flex flex-col flex-grow self-stretch h-full max-h-full'>
            <Switch>
              <PublicOnlyRoute exact auth={this.props.auth} path='/' component={HomeContainer} />

              <PrivateRoute exact auth={this.props.auth} path='/directory' component={DirectoryContainer} />
              <PrivateRoute exact auth={this.props.auth} path='/dialer' component={DialerContainer} />
              <PrivateRoute exact auth={this.props.auth} path='/conversations/:number' component={ConversationContainer} />
              <PrivateRoute exact auth={this.props.auth} path='/settings' component={SettingsContainer} />

              <Route component={NoMatch} />
            </Switch>
          </div>
        </Router>
      </div>
    )
  }
};

export default withAuth(App)
