import React from 'react'
import PropTypes from 'prop-types'

const TextElement = ({ message }) => {
  return (
    <div className='element pb-2'>
      <p>{message}</p>
    </div>
  )
}

const Action = ({ id, label, onClick, url, disabled }) => {
  return (
    <button className='px-4 py-2 rounded-lg bg-gray-400 mr-3 last:mr-0' id={id} onClick={onClick} value={url} disabled={disabled}>{label || 'Submit'}</button>
  )
}

const ButtonElement = ({ id, label, onClick, value, url }) => {
  return (
    <div className='element'>
      <button className='p-2 rounded bg-gray-400 mx-2' id={id} onClick={onClick} value={value || url}>{label}</button>
    </div>
  )
}

const TextInputElement = (props) => {
  return React.createElement(UntypedInputElement,
    Object.assign({}, props, { type: 'text' })
  )
}

const PhoneInputElement = (props) => {
  return React.createElement(UntypedInputElement,
    Object.assign({}, props, { type: 'tel' })
  )
}

const DigitInputElement = (props) => {
  return React.createElement(UntypedInputElement,
    Object.assign({}, props, { type: 'number' })
  )
}

const PasswordInputElement = (props) => {
  return React.createElement(UntypedInputElement,
    Object.assign({}, props, { type: 'password' })
  )
}

const imageSrc = (src, jwt) => {
  let srcUrl
  try {
    srcUrl = new window.URL(src)
  } catch (error) {
    console.warn('Error rendoring image')
    console.warn(error)
    srcUrl = new window.URL('http://placehold.jp/150x150.png')
  }

  if (srcUrl.protocol.startsWith('http') && (srcUrl.hostname.endsWith('smartprompt.test') || srcUrl.hostname.endsWith('smartprompt.com'))) {
    return src + '?jwt=' + jwt
  } else {
    return srcUrl
  }
}

const ImageInputElement = (props) => {
  return UntypedImageInputElement('User Image Required', props)
}

const DrawInputElement = (props) => {
  return UntypedImageInputElement('User Draw Required', props)
}

const ImageElement = ({ label, url, id }) => {
  return (
    <div className='element'>
      <img className='img-fluid' src={url} alt={label} id={id} />
    </div>
  )
}

const UnknownElement = (props) => {
  return (
    <div className='element'>
      <strong>Unknown Element</strong><br />
      {JSON.stringify(props)}
    </div>
  )
}

const SingleSelectElement = ({ id, options, onClick }) => {
  const buttons = options.map((opt) => {
    return React.createElement(
      ButtonElement,
      Object.assign(opt, { key: id, onClick: onClick })
    )
  })
  return (
    <div className='element'>
      {buttons}
    </div>
  )
}

const UntypedImageInputElement = (inputLabel, { id, label, value, jwt }) => {
  if (value) {
    return (
      <div className='element'>
        <div className='imagecontainer'>
          <img className='w-full mx-auto' src={imageSrc(value, jwt)} alt={label} id={id} />
        </div>
        <strong>{label}</strong>
      </div>
    )
  } else {
    return (
      <div className='element'>
        <button disabled>{inputLabel}: {label}</button>
      </div>
    )
  }
}

const UntypedInputElement = ({ id, type, value, placeholder, onChange, disabled }) => {
  return (
    <div className='element'>
      <input
        className='rounded mb-2 bg-gray-100 border-gray-600 p-2 w-full'
        id={id}
        name={id}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  )
}

const ElementComps = {
  text: TextElement,
  button: ButtonElement,
  image: ImageElement,
  text_input: TextInputElement,
  phone_input: PhoneInputElement,
  digit_input: DigitInputElement,
  password_input: PasswordInputElement,
  image_input: ImageInputElement,
  draw_input: DrawInputElement,
  single_select: SingleSelectElement
}

class WidgetMessage extends React.PureComponent {
  constructor (props) {
    super(props)

    this.bottomRef = this.props.bottom
    this.state = {
      formId: this.props.message.id,
      elements: this.getWidgetValues(),
      jwt: this.props.jwt
    }

    this.elOnClick = this.elOnClick.bind(this)
    this.elOnChange = this.elOnChange.bind(this)
    this.onFormClick = this.onFormClick.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  // Redo the elements state when a child message is set on the message
  componentDidUpdate (prevProps) {
    if (!prevProps.message.child && this.props.message.child) {
      this.setState(Object.assign({}, this.state, {
        elements: this.getWidgetValues()
      }))
    }
  }

  getWidgetValues () {
    const child = this.props.message.child
    return this.props.message.body.elements.reduce((acc, el) => {
      let value = el.default_value
      if (child) {
        const values = child.body.data.find(childEl => childEl.id === el.id)
        if (values) {
          value = values.value
        }
      }
      acc[el.id] = {
        id: el.id,
        type: el.type,
        value: value
      }
      return acc
    },
    {})
  }

  elOnClick (e) {
    e.preventDefault()
  }

  elOnChange (e) {
    e.preventDefault()

    const id = e.target.id

    const elValues = this.state.elements
    elValues[id].value = e.target.value

    this.setState(Object.assign({}, this.state, { elements: elValues }))
  }

  onFormClick (e) {
    e.preventDefault()

    const data = Object.keys(this.state.elements).map((key, _index) => {
      return this.state.elements[key]
    })

    this.props.widgetAction(Object.assign({
      id: this.state.formId,
      url: e.target.value,
      data: data
    }))
  }

  onSubmit (e) {
    e.preventDefault()
  }

  render () {
    const elValues = this.state.elements

    const widget = this.props.message

    const elements = widget.body.elements.map(el => {
      const comp = ElementComps[el.type] || UnknownElement
      const value = elValues[el.id].value
      const id = el.id + el.message
      return React.createElement(comp,
        Object.assign({
          key: id,
          value: value,
          onChange: this.elOnChange,
          jwt: this.state.jwt
        }, el)
      )
    })

    const justify = widget.from === 'user' ? 'justify-end' : 'justify-start'
    const widgetClasses = widget.from === 'user' ? 'text-right ml-8 bg-blue-200' : 'mr-8 bg-gray-200'

    let disabled = false
    let url = null
    if (widget.child) {
      disabled = true
      url = widget.child.body.url
    }
    const actions = widget.body.actions.filter(action => url === null || action.url === url).map((action) => {
      return React.createElement(
        Action,
        Object.assign({ key: action.url, onClick: this.onFormClick, disabled: disabled }, action)
      )
    })

    return (
      <div className={`flex ${justify}`}>
        <div className={`widget ${widget.from} ${widgetClasses} p-3 mb-2 rounded-lg w-full`}>
          <div>
            <form ref={this.bottomRef} id={widget.id} onSubmit={this.onSubmit}>
              {elements}
              <div className='element'>
                {actions}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

WidgetMessage.propTypes = {
  message: PropTypes.object.isRequired,
  bottom: PropTypes.object.isRequired,
  jwt: PropTypes.string.isRequired
}

export default WidgetMessage
